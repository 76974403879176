<template>
  <transition-group id="answersList" :name="voteConfig.orderAnimation ? 'flip-list' : ''" tag="ul">
    <li v-for="(choice, idx) in sortedChoices" :key="choice.id" class="responseRow row">
      <div :class="voteConfig.answerTextPosition === 'left' ? 'col-auto' :'col-12'">
        <h2 :style="answerTextStyle(choice.id)" class="responseText mt-4">
          <span  v-if="voteConfig.showIndex">{{ idx + 1 }} - </span><span v-html="choice.text"></span>
        </h2>
      </div>
      <div class="bar col" v-if="isShowBars">
        <div :style="barBackgroundStyle" class="barBackground progress w-100">
          <div class="barForeground progress-bar" role="progressbar" :style="barForegroundStyle(choice.id)" :aria-valuenow="barForegroundAria(choice.id)" aria-valuemin="0" :aria-valuemax="barForegroundAriaValueMax"></div>
        </div>
      </div>
      <div v-if="showRightValue" class="col-auto d-flex justify-content-center align-items-center">
        <h3 :style="answerTextStyle(choice.id)" class="value my-auto">{{ getRightValue(choice.id) }}</h3>
      </div>
    </li>
  </transition-group>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'VoteBarRender',
  props: {
    question: {
      type: Object,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['voteConfig', 'currentCommand']),
    sortedChoices()
    {
      const out = [...this.choices];
      if (this.voteConfig.orderAnimation)
        return out.sort((a, b) => this.getAnswerCountForAnswer(b.id) - this.getAnswerCountForAnswer(a.id));
      return out;
    },
    /**
     * Return true if the commmand ask to show the bars
     * @return {boolean}
     */
    isShowBars()
    {
      return this.currentCommand !== 'display-question-answers' && this.currentCommand !== 'display-question';
    },

    /**
     * Return true if the command ask to show the bars
     * AND the config 'valueStyle' is not set to 'hide' ('percent' or 'value' then ...)
     * @return {boolean}
     */
    showRightValue()
    {
      return this.voteConfig.valueStyle !== 'hide' && this.isShowBars;
    },

    /**
     * bar background style binding
     * @return {{backgroundColor: string, height: string}}
     */
    barBackgroundStyle()
    {
      const out = { height: '50px' };
      if (this.voteConfig.backgroundBarColor && this.voteConfig.backgroundBarColor.hex8)
        out.backgroundColor = this.voteConfig.backgroundBarColor.hex8 || '';
      return out;
    },
    /**
     * Return the max value aria for the foreground bar (needed by Boostrap ?)
     * @return {number}
     */
    barForegroundAriaValueMax()
    {
      return this.getTotalAnswerCount();
    },
  },
  methods: {
    /**
     * Return the Text style for the question (font family, size, color etc ... )
     * The text color depend on the current command and if this is a right answer
     * @param ansKey the answer Identifier
     * @return {{fontFamily: string, color: string, fontSize: string|string}}
     */
    answerTextStyle(ansKey)
    {
      const style = {
        color: this.voteConfig.fontColor.hex,
        fontSize: this.fontSize,
        fontFamily: this.voteConfig.fontFamily,
      };
      if (this.currentCommand === 'display-good-answers' && this.isGoodAnswer(ansKey))
        style.color = this.voteConfig.barGoodAnswerColor.hex;
      return style;
    },
    /**
     * Return the foreground bar Style
     * @param barKey the bar Identifier ( aka the answer Identifier)
     * @return {{backgroundColor, width: string}}
     */
    barForegroundStyle(barKey)
    {
      let percent = 0;
      percent = (this.getAnswerCountForAnswer(barKey) * 100) / ((this.getTotalAnswerCount() > 0) ? this.getTotalAnswerCount() : 1);// avoid dividing by 0

      let color = this.voteConfig.barColor.hex;

      console.log(this.voteConfig.barGoodAnswerColor.hex);
      if (this.currentCommand === 'display-good-answers' && this.isGoodAnswer(barKey))
        color = this.voteConfig.barGoodAnswerColor.hex;
      return {
        width: `${percent}%`,
        backgroundColor: color,
      };
    },
    /**
     * Return the foreground bar aria (needed by Boostrap ?)
     * @param barKey the bar Identifier ( aka the answer Identifier)
     * @return {number}
     */
    barForegroundAria(barKey)
    {
      return this.getAnswerCountForAnswer(barKey);
    },
    /**
     * return the value displayed on the right part of the answer
     * if the configuration value style if set to 'percent', the function will compute the percent value and suffix it by '%'
     * or return the full value otherwise
     * @param barKey the bar Identifier ( aka the answer Identifier)
     * @return {string|number|number}
     */
    getRightValue(barKey)
    {
      if (this.voteConfig.valueStyle === 'percent')
      {
        let percent = 0;
        percent = (this.getAnswerCountForAnswer(barKey) * 100) / ((this.getTotalAnswerCount() > 0) ? this.getTotalAnswerCount() : 1);// avoid dividing by 0
        if (percent % 1 !== 0)
          return `${percent.toFixed(1)}%`;
        return `${percent}%`;
      }

      return this.getAnswerCountForAnswer(barKey);
    },

    /**
     * Return the count of answer for the current Question and the given answer
     * @param ansKey the answer Identifier
     * @return {number}
     */
    getAnswerCountForAnswer(ansKey)
    {
      let count = 0;
      Object.entries(this.answers).forEach(([, value]) => {
        value.forEach((rep) => {
          count += (rep === ansKey) ? 1 : 0;
        });
      });
      return count;
    },

    /**
     *  Return the total answer count for the current question
     *  @return {number}
     */
    getTotalAnswerCount()
    {
      // check if answers are empty
      if (!this.answers)
        return 0;
      return Object.keys(this.answers).length;
    },
    /**
     * Return true if the answers identified by ansKey is in the good array of the current Vote
     * aka : true if this is the right answer to the question
     * @param ansKey the answer Identifier
     * @return {boolean}
     */
    isGoodAnswer(ansKey)
    {
      let found = false;
      if (this.question.goods === null || this.question.goods === undefined)
        return false;
      this.question.goods.forEach((value) => {
        if (value === ansKey)
          found = true;
      });
      return found;
    },
  },
};

</script>

<style scoped>

.progress-bar {
  border-radius: .25rem;
}

.progress {
  border-radius: .25rem;
}

.flip-list-move {
  transition: transform 1s;
}
.flip-list-leave-active {
  transition: none;
}

ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

</style>
